export enum Constants {
  SHOW = 'PROGRESS_LOADER_SHOW',
  HIDE = 'PROGRESS_LOADER_HIDE'
}

export interface IProgressLoaderAction {
  type: string;
}

export interface IProgressLoaderState {
  isVisible: boolean;
}