import { IToasterMessage } from "../../../components/Toaster";

export enum Constants {
  SHOW = 'TOAST_SHOW',
  HIDE = 'TOAST_HIDE'
}

export interface IToasterAction {
  type: string;
  msg: IToasterMessage | null
}

export interface IToasterState {
  msg: IToasterMessage | null
}