import React from 'react';
import { useSelector } from 'react-redux';
import TabMenu from '../../components/TabMenu';
import { makeUser } from '../LoginPage/modules/selectors';
import { IUser, UserRole } from '../../services/types';
import { Pages } from '../../constants';

interface Props {
}

const TabMenuWrapper: React.FunctionComponent<Props> = () => {
  const user = useSelector(state => makeUser()(state)) as IUser;

  const items = [
    { name: 'Statistics', route: Pages.Statistics },
    { name: 'Patients', route: Pages.Patients },
    { name: 'Devices', route: Pages.Devices },
  ];
  if(user.role === UserRole.Admin) {
    items.push({ name: 'Users', route: Pages.Users });
    items.push({ name: 'Logs', route: Pages.Logs });
    items.push({ name: 'Report v1', route: Pages.Report });
    items.push({ name: 'Report v2', route: Pages.Bireport });
  }
  return <TabMenu items={items} />
}

export default TabMenuWrapper;