import { Constants, IUserAction, IUserState } from './types';

const init: IUserState = {
  users: [],
  user: null
}

export default function UsersReducer(state: IUserState = init, action: IUserAction): IUserState {
  switch (action.type) {
    case Constants.FETCH_USERS_END:
      return { ...state, users: action.users || [] };
    case Constants.SET_USER_TO_EDIT:
      return { ...state, user: action.user || null };
    default:
      return state;
  }
}