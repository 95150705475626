import React from 'react';
import { connect } from 'react-redux';
import { makeIsVisible } from './modules/selectors';
import ProgressLoader from '../../components/ProgressLoader';

interface Props {
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => {
  const isVisible = makeIsVisible()(state)
  return {
    isVisible
  };
};

const ConnectedProgressLoader: React.ComponentType<Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProgressLoader);

export default ConnectedProgressLoader;