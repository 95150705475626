import { Constants, INavbarNotifierAction, INavbarNotifierState } from './types';

const init: INavbarNotifierState = {
  notifications: [],
  timestamp: undefined
}

export default function NavbarNotifierReducer(state: INavbarNotifierState = init, action: INavbarNotifierAction): INavbarNotifierState {
  switch (action.type) {
    case Constants.FETCH_NOTIFICATIONS_END: {
      const n = action.notifications || [];
      let nTimestamp = state.timestamp;
      n.forEach(item => {
        if (nTimestamp === undefined || item.createdAt > nTimestamp) {
          nTimestamp = item.createdAt;
        }
      });
      return { ...state, notifications: n, timestamp: nTimestamp };
    }
    case Constants.CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] };
    default:
      return state;
  }
}