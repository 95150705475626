import { IUser } from '../../../services/types';

export enum Constants {
  LOGIN_START = 'LOGIN_START',
  SET_USER = 'SET_USER',
  SHOW_IDLE_MODAL = 'SHOW_IDLE_MODAL',
  LOGOUT = 'LOGOUT',
  FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_END = 'FORGOT_PASSWORD_END',
}

export interface ILoginAction {
  type: string;
  showIdleModal?: boolean;
  user?: IUser | null;
}

export interface ILoginState {
  user: IUser | null;
  showIdleModal: boolean;
}