import React from 'react';
import { connect } from 'react-redux';
import { Navbar } from '../../components/Navbar';
import { makeUser } from '../LoginPage/modules/selectors';

interface Props {
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => {
  const user = makeUser()(state);
  return {
    user
  };
};

const ConnectedNavbar: React.ComponentType<Props> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navbar);

export default ConnectedNavbar;