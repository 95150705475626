/**
 * Asynchronously loads the component
 */
import React from 'react';
import baseLoadable from '@loadable/component';

import Loader from '../ProgressLoader';

const Loadable = (element) => baseLoadable(element, {
  fallback: <Loader />,
});

export default Loadable;
