import { Constants, IDevicesAction, IDevicesState } from './types';

const init: IDevicesState = {
  devices: [],
}

export default function DevicePageReducer(state: IDevicesState = init, action: IDevicesAction): IDevicesState {
  switch (action.type) {
    case Constants.FETCH_DEVICES_END:
      return { ...state, devices: action.devices || [] };
    default:
      return state;
  }
}