import { INotification } from "../../../services/types";

export enum Constants {
  FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START',
  FETCH_NOTIFICATIONS_END = 'FETCH_NOTIFICATIONS_END',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
}

export interface INavbarNotifierAction {
  type: string;
  notifications?: INotification[]
}

export interface INavbarNotifierState {
  notifications: INotification[],
  timestamp?: Date
}