import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';

export interface IToasterMessage {
  severity: 'warn' | 'success' | 'error',
  summary: string,
  detail: string,
  life?: number,
}

type Props = {
  msg: IToasterMessage | null
}

const Toaster: React.FunctionComponent<Props> = ({ msg }) => {
  const toast = useRef<any>(null);
  useEffect(() => {
    if (msg === null) {
      toast.current.clear();
    } else {
      const msgToShow = { ...msg };
      if (!msgToShow.life) {
        msgToShow.life = 3000;
      }
      toast.current.show(msgToShow);
    }
  }, [msg]);
  return <Toast ref={toast} />
}

export default Toaster