import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { fetchNotifications as getNotifications, clearAll } from './modules/actions';
import BellIcon from '../../components/Icons/bell';
import { makeNotifications, makeTimestamp } from './modules/selectors';
import { INotification } from '../../services/types';
import InfoIcon from '../../components/Icons/info';
import './style.css';

const mapDispatchToProps = dispatch => ({
  fetchNotifications: (timestamp) => getNotifications(dispatch, timestamp),
  clearAllNotifications: (ids) => clearAll(dispatch, ids),
});

const mapStateToProps = state => {
  const notifications = makeNotifications()(state);
  const timestamp = makeTimestamp()(state);
  return {
    ...state,
    notifications,
    timestamp
  };
};

interface Props {
  notifications: INotification[],
  timestamp?: Date,
  fetchNotifications(timestamp?: Date): Promise<void>,
  clearAllNotifications(ids: string[]): void
}

const NavbarNotifier: React.FunctionComponent<Props> = ({ notifications, timestamp, fetchNotifications, clearAllNotifications }) => {
  let intervalId;
  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      fetchNotifications(timestamp);
    }, 10000);
    return () => {
      clearInterval(intervalId);
    }
  }, [timestamp]);

  const notificationMenu = useRef(null);
  const openMenu = (e, nm) => nm.current.toggle(e)
  return <div className="notifier-container">
    <div role="button" aria-label="Notification" tabIndex={-1} onClick={(e) => openMenu(e, notificationMenu)} onKeyPress={() => false}>
      {notifications.length > 0 && <Badge value={notifications.length} className="p-mr-2" severity="danger"></Badge>}
      <BellIcon />
    </div>
    <OverlayPanel ref={notificationMenu} dismissable className="notifier-overlay">
      {notifications.length === 0 && 'No new notifications'}
      {
        notifications.length > 0 && <div className="p-grid p-dir-col notifier-overlay-content">
          {notifications.map(n => <div className="p-col" key={n.id}>
            <div className="p-d-flex p-ai-center">
              <div className="p-mr-2">
                <div className="circle"><InfoIcon /></div>
              </div>
              <div className="p-d-flex p-flex-column">
                <div className="name">{n.name}</div>
                <div className="description">{n.description}</div>
              </div>
            </div>
          </div>
          )}
        </div>
      }
      {
        notifications.length > 0 && <div className="notifier-footer">
          <Button label="Clear All" className="p-button-text p-button-plain" onClick={()=> clearAllNotifications(notifications.map(n => n.id))} />
        </div>
      }
    </OverlayPanel>
  </div>
}

const ConnectedNavbarNotifier: React.ComponentType<any> = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavbarNotifier);

export default ConnectedNavbarNotifier;