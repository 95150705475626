import { IPatient } from '../../../services/types';

export enum Constants {
  FETCH_PATIENTS_START = 'FETCH_PATIENTS_START',
  FETCH_PATIENTS_END = 'FETCH_PATIENTS_END',
  FETCH_PATIENT_SERIES_START = 'FETCH_PATIENT_SERIES_START',
  FETCH_PATIENT_SERIES_END = 'FETCH_PATIENT_SERIES_END',
}

export interface IPatientsAction {
  type: string;
  patients?: IPatient[];
}

export interface IPatientsState {
  patients: IPatient[];
}