import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';

type TabProps = {
  items: {
    name: string,
    route: string,
    onClick?(): void
  }[];
}

const TabMenuComponent: React.FunctionComponent<TabProps> = ({ items }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return <div className="tab-menu">
    {items.map((item, index) => {
      return <div
        role="button"
        onKeyPress={() => false}
        aria-label="pdf-link"
        tabIndex={index}
        onClick={() => {
          navigate(item.route);

          if(item.onClick) {
            item.onClick();
          }
        }}
        className={location.pathname === item.route ? "selected" : ""}
        key={item.name}
      >
        {item.name}
      </div>
    })}
  </div>
}

export default TabMenuComponent;