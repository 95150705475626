import { Constants, ILoginAction, ILoginState } from './types';

const init: ILoginState = {
  user: null,
  showIdleModal: false
}

export default function LoginReducer(state: ILoginState = init, action: ILoginAction): ILoginState {
  switch (action.type) {
    case Constants.LOGIN_START:
      return { ...state, user: null };
    case Constants.LOGOUT:
      return { ...state, user: null, showIdleModal: false };
    case Constants.SET_USER:
      return { ...state, user: action.user || null };
    case Constants.SHOW_IDLE_MODAL:
      return { ...state, showIdleModal: action.showIdleModal || false };
    default:
      return state;
  }
}