import moment from "moment";
import { TokenKey } from "../containers/LoginPage/modules/actions";
import CookieUtils from "./cookieUtils";

export default class BaseService {
  url: string;
  turnOffRequests = false;
  constructor(url: string, controllerUrl: string) {
    this.url = url;
  }

  public setTurnOffRequests(turnOff: boolean) {
    this.turnOffRequests = turnOff;
  }

  protected async fetch(endPoint: string, options: any = {}): Promise<Response> {
    if(this.turnOffRequests) {
      return Promise.resolve(null as any);
    }
    const token = localStorage.getItem(TokenKey);
    const authHeader = {};
    if (token) {
      authHeader['Authorization'] = `Bearer ${token}`;
    }
    const requestOptions = {
      headers:
      {
        ...options.headers,
        ...authHeader,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    };

    return fetch(`${this.url}/${endPoint}`, requestOptions);
  }

  protected async api<T>(endPoint: string, options: any = {}): Promise<T> {
    if (this.turnOffRequests) {
      return Promise.resolve(null as any);
    }
    return this.fetch(endPoint, options)
      .then(async (response) => {
        if (response.ok) {
          if (response.status === 204) {
            return Promise.resolve(null);
          }
          const res = await response.json();

          if (res.status !== 200 && res.message) {
            throw new Error(res.message);
          }

          return res;
        }
        if (response.status === 401) {
          CookieUtils.remove(CookieUtils.ssnStr);
          window.location.href = window.env.LOGIN_URL;
          return Promise.resolve({});
        }
        const httpError = await response.json();
        throw new Error(httpError.message);
      })
  }

  private static getOptions(method: string, data: any = null) {
    const options: { method?: string, body?: string } = {
      method
    };
    if (data) {
      options.body = JSON.stringify(data);
    }

    return options;
  }

  protected async post<T>(endPoint: string, data: any = null): Promise<T> {
    return this.api<T>(endPoint, BaseService.getOptions('POST', data));
  }

  protected async delete<T>(endPoint: string, data: any = null): Promise<T> {
    return this.api<T>(endPoint, BaseService.getOptions('DELETE', data));
  }

  protected async put<T>(endPoint: string, data: any = null): Promise<T> {
    return this.api<T>(endPoint, BaseService.getOptions('PUT', data));
  }

  protected getQuery(search: any): string {
    const esc = encodeURIComponent;
    const query = Object.keys(search).filter(k => !!search[k]).map(k => {
      if (search[k] instanceof Date) {
        return `${k}=${moment(search[k]).format('YYYY-MM-DD')}`;
      }
      return `${esc(k)}=${esc(search[k])}`;
    }).join('&');

    return query;
  }
}