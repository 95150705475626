import { FilterType } from '../utils/filterManager';
import BaseService from './base';
import { IDevice, IDeviceLog, INamedModel, INotification, IPatient, ISeries, IUser, IStatisticItem, IDictionary } from './types';
import { IDeviceFilter, IPatientFilter, IDeviceLogFilter, IUserFilter, IFilter, TimeFilter, IPatientFilterThreshold } from './filterTypes';
import { HttpError, IService } from './provider';

export default class Service extends BaseService implements IService {
  forgotPassword(email: string): Promise<void> {
    return super.post<any>(`auth/forgotPassword/${email}`);
  }

  resetPassword(token: string, email: string, password: string): Promise<void> {
    return super.post<any>(`auth/resetPassword`, {
      token,
      email,
      password,
    });
  }

  saveUser(user: IUser): Promise<IUser | HttpError> {
    return super.post(`user`, user);
  }

  fetchUser(id: string): Promise<IUser | HttpError> {
    return super.api<IUser>(`user/${id}`);
  }

  fetchSeriesStatisticByTime(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    return super.post(`series/statistic/time`, {
      timeFilter,
      threshold
    });
  }

  fetchSeriesStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    return super.post(`series/statistic`, {
      timeFilter,
      threshold
    });
  }

  fetchPatientStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    return super.post(`patient/statistic`, {
      timeFilter,
      threshold
    });
  }

  fetchDeviceStatistic(timeFilter: TimeFilter): Promise<IStatisticItem[]> {
    return super.api<IStatisticItem[]>(`device/statistic?timeFilter=${timeFilter}`);
  }

  fetchDeviceLogStatistic(timeFilter: TimeFilter): Promise<IStatisticItem[]> {
    return super.api<IStatisticItem[]>(`deviceLog/statistic?timeFilter=${timeFilter}`);
  }

  fetchDeviceNamedModels(): Promise<INamedModel[]> {
    return super.api<INamedModel[]>(`device/named`);
  }

  fetchDeviceLogs(search?: IDeviceLogFilter): Promise<IDeviceLog[]> {
    const query = this.getQuery(search);

    return query === '' ?
      super.api<IDeviceLog[]>(`deviceLog`) :
      super.api<IDeviceLog[]>(`deviceLog?${query}`);
  }

  saveFilter(name: string, filterJson: string, type: FilterType, userId: string, id?: string): Promise<void> {
    return super.post('filter', {
      id,
      name,
      userId,
      filterJson,
      type
    });
  }

  fetchFilters(userId: string, type: FilterType): Promise<IFilter[]> {
    return super.api<IFilter[]>(`filter?userId=${userId}&type=${type}`);
  }

  fetchUsers(search: IUserFilter): Promise<IUser[]> {
    const query = this.getQuery(search);

    return query === '' ?
      super.api<IUser[]>(`user`) :
      super.api<IUser[]>(`user?${query}`);
  }

  setFavoriteUser(id: string, isFavorite: boolean): Promise<void> {
    return this.put(`user`, { id, isFavorite });
  }

  fetchPatientSeries(patientId: string): Promise<ISeries[]> {
    return super.api<ISeries[]>(`series/${patientId}`);
  }

  fetchExtendedPatientSeries(sql: string, parameters: IDictionary<string>): Promise<ISeries[]> {
    return super.post('series/query', { sql: sql.replace('createdAt =', `CAST(createdAt AS DATE) =`), parameters })
  }

  fetchDeviceErrors(deviceId: string): Promise<IDeviceLog[]> {
    return super.api<IDeviceLog[]>(`deviceLog/${deviceId}`);
  }

  fetchNotifications(timestamp?: Date): Promise<INotification[]> {
    return super.api<INotification[]>(`eventLog`);
  }

  clearNotifications(ids: string[]): Promise<void> {
    return super.post('eventLog', ids);
  }

  fetchPatients(search: IPatientFilter): Promise<IPatient[]> {
    return super.post<IPatient[]>('patient/list', search);
  }

  setFavoritePatient(id: string, isFavorite: boolean): Promise<void> {
    return this.put(`patient`, { id, isFavorite });
  }

  setFavoriteDevice(id: string, isFavorite: boolean): Promise<void> {
    return this.put(`device`, { id, isFavorite });
  }

  fetchDevices(search: IDeviceFilter): Promise<IDevice[]> {
    const query = this.getQuery(search);

    return query === '' ?
      super.api<IDevice[]>(`device`) :
      super.api<IDevice[]>(`device?${query}`);
  }

  login(email: string, password: string): Promise<IUser | HttpError> {
    return this.post('auth/login', {
      email,
      password
    });
  }
}