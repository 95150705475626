import React from 'react'
import { NavLink } from 'react-router-dom';
import ConnectedNavbarNotifier from '../../containers/NavbarNotifier';
import ConnectedNavbarProfiler from '../../containers/NavbarProfiler';
import TabMenuWrapper from '../../containers/TabMenu';
import { IUser } from '../../services/types';
import LogoIcon from '../Icons/logo';
import './style.css';

interface Props {
  user: IUser
}

export const Navbar: React.FC<Props> = ({ user }) => {
  return <div className="p-d-flex p-jc-between nav-panel">
    <div className="dashboard-logo-container">
      <NavLink to="/">
        <LogoIcon className="dashboard-logo" />
      </NavLink>
    </div>
    <div className="nav-bar-right-panel">
      <TabMenuWrapper />
      <ConnectedNavbarNotifier />
      <ConnectedNavbarProfiler user={user} />
    </div>
  </div>
}