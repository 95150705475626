import React, { ReactNode, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeUser } from '../LoginPage/modules/selectors'
import { TokenKey } from '../LoginPage/modules/actions';
import { hide, show } from '../ProgressLoader/modules/actions';
import ServiceProvider from '../../services/provider';
import { Constants } from '../LoginPage/modules/types';

type Props = {
  children: ReactNode;
}

const UserPermissions: React.FunctionComponent<Props> = ({ children }) => {
  const userFromStore = useSelector(state => makeUser()(state));
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(TokenKey);
    if (token && !userFromStore) {
      show(dispatch);
      const decoded = jwt_decode(token);
      ServiceProvider.Service.fetchUser((decoded as any).Id)
        .then((resp: any) => {
          dispatch({
            type: Constants.SET_USER,
            user: {
              firstName: resp.firstName,
              lastName: resp.lastName,
              id: resp.id,
              isFavorite: resp.isFavorite,
              email: resp.email,
              phone: resp.phone,
              role: resp.role
            },
          });
        })
        .catch(() => {
          localStorage.removeItem(TokenKey);
        })
        .finally(() => {
          setIsLoading(false);
          hide(dispatch);
        })
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  return <>
    {children}
  </>;
}

export default UserPermissions;