import { Dispatch } from 'redux';
import { Constants, IProgressLoaderAction } from './types';

export function show(dispatch: Dispatch<IProgressLoaderAction>) {
  dispatch({
    type: Constants.SHOW
  });
}

export function hide(dispatch: Dispatch<IProgressLoaderAction>) {
  dispatch({
    type: Constants.HIDE
  });
}