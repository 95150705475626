import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import './style.css';

type Props = {
  isVisible: boolean
}

const ProgressLoader: React.FunctionComponent<Props> = ({ isVisible }) => {
  return isVisible ? <div className="progress-loader">
    <LinearProgress color="secondary" />
    <div className="modal-backdrop" />
  </div> : null
}

export default ProgressLoader