import { Dispatch } from 'redux';
import ServiceProvider from '../../../services/provider';
import { Constants, ILoginAction } from './types';
import { hide, show } from '../../ProgressLoader/modules/actions';

export const TokenKey = 'nicas:token';
export const IdleKey = 'nicas:expiredTime';

export const logout = (dispatch: Dispatch<ILoginAction>) => {
  dispatch({
    type: Constants.SET_USER,
    user: null,
    showIdleModal: false
  });
}

export const showIdleModal = (dispatch: Dispatch<ILoginAction>, showModal: boolean) => {
  dispatch({
    type: Constants.SHOW_IDLE_MODAL,
    showIdleModal: showModal
  });
}

export const login = (dispatch: Dispatch<ILoginAction>, email: string, password: string) => {
  dispatch({
    type: Constants.LOGIN_START
  });
  show(dispatch);
  return ServiceProvider.Service.login(email, password)
    .then((response) => {
      localStorage.removeItem(IdleKey);
      const resp = response as any;
      dispatch({
        type: Constants.SET_USER,
        user: {
          firstName: resp.firstName,
          lastName: resp.lastName,
          id: resp.id,
          isFavorite: resp.isFavorite,
          email: resp.email,
          phone: resp.phone,
          role: resp.role,
          branch: resp.branch
        },
      });
      localStorage.setItem(TokenKey, (response as any).token);
      hide(dispatch);
      return response;
    });
}

export const sendResetInstructions = (dispatch: Dispatch<ILoginAction>, email: string) => {
  dispatch({
    type: Constants.FORGOT_PASSWORD_START
  });
  show(dispatch);
  return ServiceProvider.Service.forgotPassword(email)
    .then((response) => {
      dispatch({
        type: Constants.FORGOT_PASSWORD_END
      });
      hide(dispatch);
      return response;
    });
}

export const resetPassword = (dispatch: Dispatch<ILoginAction>, token: string, email: string, password: string) => {
  show(dispatch);
  return ServiceProvider.Service.resetPassword(token, email, password)
    .then((response) => {
      hide(dispatch);
      return response;
    });
}