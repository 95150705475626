import React, { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OverlayPanel } from "primereact/overlaypanel";
import { IUser } from "../../services/types";
import { IdleKey, logout, TokenKey } from "../LoginPage/modules/actions";
import "./style.css";

interface Props {
  user: IUser;
}

export const onLogout = (nav, doLogout) => {
  doLogout();
  localStorage.removeItem(IdleKey);
  localStorage.removeItem(TokenKey);
  nav(window.env.LOGIN_URL);
};

const NavbarProfiler: React.FunctionComponent<Props> = ({ user }) => {
  const profilerMenu = useRef(null);
  const dispatcher = useDispatch();
  const openMenu = (e, nm) => nm.current.toggle(e);
  const onChangePassword = () => {
    alert("a");
  };
  const navigate = useNavigate();

  return (
    <div className="notifier-container notifier-profiler">
      <span
        className="pi pi-user-profile"
        role="button"
        tabIndex={-1}
        onClick={(e) => openMenu(e, profilerMenu)}
        onKeyPress={() => false}
      >
        <span>
          {`${user.firstName[0].toUpperCase()}${user.lastName[0].toUpperCase()}`}
        </span>
      </span>
      <span
        role="button"
        tabIndex={-1}
        onClick={(e) => openMenu(e, profilerMenu)}
        onKeyPress={() => false}
      >
        {user.firstName}
        &nbsp;
        {user.lastName}
      </span>
      <OverlayPanel ref={profilerMenu} dismissable className="profiler-overlay">
        <div style={{ width: 130, textAlign: "left" }}>
          <a onClick={() => onLogout(navigate, () => logout(dispatcher))}>
            Logout
          </a>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default NavbarProfiler;
