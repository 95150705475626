import { FilterType } from '../utils/filterManager';
import Service from './service';
import StubService from './stubService';
import { IDevice, IDeviceLog, INamedModel, INotification, IPatient, ISeries, IUser, IStatisticItem, IDictionary } from './types';
import { IDeviceFilter, IPatientFilter, IDeviceLogFilter, IUserFilter, IFilter, TimeFilter, IPatientFilterThreshold } from './filterTypes';
import { Pages } from '../constants';

type EnvConfig = {
  API_URL: string;
  LOGIN_URL: string;
}

declare global {
  interface Window { env: EnvConfig }
}

window.env = {  
  //API_URL: 'http://ramzes84kha-001-site1.ktempurl.com/api',
  API_URL: 'https://nicas.somee.com/api',
  //API_URL: 'http://13.83.91.7/nicascloud/api',
  // API_URL: 'https://localhost:44383
  // API_URL: '',
  LOGIN_URL: Pages.Login,
}

export type HttpError = {
  status: number;
  message: string;
}

export interface IService {
  login(email: string, password: string): Promise<IUser | HttpError>;
  saveUser(user: IUser): Promise<IUser | HttpError>;
  fetchUser(id: string): Promise<IUser | HttpError>;
  fetchDevices(search: IDeviceFilter): Promise<IDevice[]>;
  fetchDeviceNamedModels(): Promise<INamedModel[]>;
  setFavoriteDevice(id: string, isFavorite: boolean): Promise<void>;
  fetchPatients(search: IPatientFilter): Promise<IPatient[]>;
  setFavoritePatient(id: string, isFavorite: boolean): Promise<void>;
  fetchNotifications(timestamp?: Date): Promise<INotification[]>;
  fetchDeviceErrors(deviceId?: string): Promise<IDeviceLog[]>;
  fetchDeviceLogs(search?: IDeviceLogFilter): Promise<IDeviceLog[]>;
  fetchPatientSeries(patientId: string): Promise<ISeries[]>;
  fetchExtendedPatientSeries(sql: string, parameters: IDictionary<string>): Promise<ISeries[]>;
  clearNotifications(ids: string[]): Promise<void>;
  fetchUsers(search: IUserFilter): Promise<IUser[]>;
  setFavoriteUser(id: string, isFavorite: boolean): Promise<void>;
  saveFilter(name: string, filterJson: string, filterType: FilterType, userId: string, id?: string): Promise<void>;
  fetchFilters(userId: string, type: FilterType): Promise<IFilter[]>;
  fetchDeviceLogStatistic(timeFilter: TimeFilter): Promise<IStatisticItem[]>;
  fetchDeviceStatistic(timeFilter: TimeFilter): Promise<IStatisticItem[]>;
  fetchPatientStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]>;
  fetchSeriesStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]>;
  fetchSeriesStatisticByTime(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]>;
  setTurnOffRequests(turnOff: boolean);
  forgotPassword(email: string): Promise<void>;
  resetPassword(token: string, email: string, password: string): Promise<void>;
}

export default class ServiceProvider {
  static service: IService = window.env && window.env.API_URL ? new Service(window.env.API_URL, '') : new StubService('', '');

  static get Service(): IService {
    return this.service;
  }

  public static setTurnOffRequests(turnOff: boolean) {
    return this.service.setTurnOffRequests(turnOff);
  }
}