import { IDeviceLog } from '../../../services/types';

export enum Constants {
  FETCH_DEVICE_ERRORS_START = 'FETCH_DEVICE_ERRORS_START',
  FETCH_DEVICE_ERRORS_END = 'FETCH_DEVICE_ERRORS_END',
}

export interface IDeviceErrorsAction {
  type: string;
  deviceErrors?: IDeviceLog[];
}

export interface IDeviceErrorsState {
  deviceErrors: IDeviceLog[];
}