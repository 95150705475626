import moment from 'moment';
import { FilterType } from '../utils/filterManager';
import BaseService from './base';
import { IDevice, IDeviceLog, INamedModel, INotification, IPatient, ISeries, IUser, IStatisticItem, IDictionary } from './types';
import { IDeviceFilter, IPatientFilter, IDeviceLogFilter, IUserFilter, IFilter, FavoriteFilterType, GenderFilterType, TimeFilter, IPatientFilterThreshold } from './filterTypes';
import { HttpError, IService } from './provider';
import { dateFormat } from '../constants';

const isStringIncludes = (s1 = '', s2 = '') => s1.toLowerCase().includes(s2.toLowerCase())

export default class StubService extends BaseService implements IService {
  forgotPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }
  resetPassword(token: string, email: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }
  saveUser(user: IUser): Promise<IUser | HttpError> {
    throw new Error('Method not implemented.');
  }
  
  fetchExtendedPatientSeries(sql: string, parameters: IDictionary<string>): Promise<ISeries[]> {
    throw new Error('Method not implemented.');
  }
  
  fetchUser(id: string): Promise<IUser | HttpError> {
    throw new Error('Method not implemented.');
  }
  
  fetchSeriesStatisticByTime(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    throw new Error('Method not implemented.');
  }
  
  saveFilter(name: string, filterJson: string, filterType: FilterType, userId: string, id?: string): Promise<void> {
    throw new Error('Method not implemented.');
  }
  
  fetchSeriesStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    return Promise.resolve([
      { name: 'Out of Threshold', value: 10 },
      { name: 'In Threshold', value: 4 },
    ]);
  }

  fetchPatientStatistic(timeFilter: TimeFilter, threshold?: IPatientFilterThreshold): Promise<IStatisticItem[]> {
    return Promise.resolve([
      { name: 'Out of Threshold', value: 10 },
      { name: 'In Threshold', value: 4 },
    ]);
  }

  fetchDeviceStatistic(createdAt: TimeFilter): Promise<IStatisticItem[]> {
    return Promise.resolve([
      { name: 'Offline', value: 10 },
      { name: 'Online', value: 4 },
    ]);
  }

  fetchDeviceLogStatistic(createdAt: TimeFilter): Promise<IStatisticItem[]> {
    return Promise.resolve([
      { name: 'trace', value: 10 },
      { name: 'info', value: 10 },
      { name: 'fatal', value: 1 }
    ]);
  }

  fetchDeviceNamedModels(): Promise<INamedModel[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.devices.map((d) => ({
          id: d.id,
          name: d.name
        })));
      }, 1000);
    });
  }

  fetchDeviceLogs(search?: IDeviceLogFilter): Promise<IDeviceLog[]> {
    return new Promise((resolve, reject) => {
      resolve(this.errors);
    });
  }
  
  fetchFilters(userId: string, type: FilterType): Promise<IFilter[]> {
    return Promise.resolve([]);
  }

  setFavoriteUser(id: string, isFavorite: boolean): Promise<void> {
    throw new Error('Method not implemented.');
  }

  fetchUsers(search: IUserFilter): Promise<IUser[]> {
    throw new Error('Method not implemented.');
  }

  clearNotifications(ids: string[]): Promise<void> {
    throw new Error('Method not implemented.');
  }

  series: ISeries[] = [
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c01', patientId: '2', comments: 'S #1', duration: 23, height: 130, weight: 80, createdAt: new Date('10/10/1999 11:30'), r: 400, hr: 406, tpri: 0.2, si: 0.3, ci: 30, cpi: 0.04, tbWa: 1, tbWr: 2, tbWw: 3, sensors: 1, position: 2, isValid: true },
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c02', patientId: '2', comments: 'S #2', duration: 67, height: 130, weight: 80, createdAt: new Date('10/10/1999 11:50'), r: 400, hr: 406, tpri: 0.2, si: 0.3, ci: 30, cpi: 0.04, tbWa: 1, tbWr: 2, tbWw: 3, sensors: 1, position: 2, isValid: true },
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c03', patientId: '2', comments: 'S #3', duration: 120, height: 130, weight: 80, createdAt: new Date(), r: 400, hr: 406, tpri: 0.2, si: 0.3, ci: 30, cpi: 0.04, tbWa: 1, tbWr: 2, tbWw: 3, sensors: 1, position: 2, isValid: true },
  ];

  fetchPatientSeries(patientId: string): Promise<ISeries[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.series);
      }, 1000);
    });
  }

  errors: IDeviceLog[] = [
    { id: 1, deviceId: '1', code: 456, message: 'After ten minutes with Ed Tettemer in the offices of the agency', logLevel: 1, createdDate: new Date('10/10/1999') },
    { id: 2, deviceId: '1', code: 500, message: 'Unable to connect to sensor 1', logLevel: 1, createdDate: new Date() },
    { id: 3, deviceId: '2', code: 500, message: 'Unable to connect to sensor 2', logLevel: 1, createdDate: new Date('10/10/1999') },
    { id: 4, deviceId: '2', code: 500, message: 'Unable to connect to network', logLevel: 1, createdDate: new Date('10/30/2000') },
    { id: 5, deviceId: '2', code: 500, message: 'Unable to connect', logLevel: 1, createdDate: new Date() },
    { id: 6, deviceId: '3', code: 444, message: 'Unexpected error', logLevel: 1, createdDate: new Date() },
    { id: 7, deviceId: '3', code: 123, message: 'Use of poor connection', logLevel: 1, createdDate: new Date('10/10/1999') },
  ];

  fetchDeviceErrors(deviceId: string): Promise<IDeviceLog[]> {
    return new Promise((resolve, reject) => {
      resolve(this.errors.filter(e => e.deviceId === deviceId));
    });
  }

  notifications: INotification[] = [
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c01', name: 'Update Patient data', description: 'Clara Hamilton was updated', createdAt: new Date() },
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c02', name: 'Add Device', description: 'NICAS-4506 was added', createdAt: new Date() },
    { id: 'ca8ce066-1f15-400f-a06b-faac84210c03', name: 'Remove Device', description: 'NICAS-6 was removed', createdAt: new Date() },
  ];

  fetchNotifications(timestamp?: Date): Promise<INotification[]> {
    return new Promise((resolve, reject) => {
      resolve(this.notifications);
    })
  }

  patients: IPatient[] = [
    { id: '1', deviceId: '1', lastName: 'Newton', height: 177, weight: 66, patient: '1', firstName: 'Clarence', mrn: '65-689-4598', gender: true, dob: new Date('10/10/1999'), lastTestAt: new Date(), isOrderReceived: false, isFavorite: false, branch: '123 Main str' },
    { id: '2', deviceId: '1', lastName: 'Hamilton', height: 1, weight: 1, patient: '1', firstName: 'Clara', mrn: '12-345-5678', gender: false, dob: new Date('10/21/2001'), lastTestAt: new Date('03/21/2021'), isOrderReceived: true, isFavorite: true, branch: '123 Main str' },
    { id: '3', deviceId: '2', lastName: 'Torres', height: 1, weight: 1, patient: '1', firstName: 'Marcus', mrn: '78-788-1248', gender: true, dob: new Date('02/26/1945'), lastTestAt: new Date('05/21/2021'), isOrderReceived: true, isFavorite: false, branch: '123 Main str' },
    { id: '4', deviceId: '3', lastName: 'Doe', height: 1, weight: 1, patient: '1', firstName: 'John', mrn: '11-222-3333', gender: true, dob: new Date('02/26/1945'), lastTestAt: new Date('05/21/2021'), isOrderReceived: true, isFavorite: false, branch: '123 Main str' },
  ];

  fetchPatients(filter: IPatientFilter): Promise<IPatient[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() =>
        resolve(this.patients.filter((d) => (filter.favorite === FavoriteFilterType.All || d.isFavorite === (filter.favorite === FavoriteFilterType.Yes)) &&
            (filter.gender === GenderFilterType.All || d.gender === (filter.gender === GenderFilterType.Male)) &&
            (!filter.deviceId || d.deviceId === filter.deviceId) &&
            (!filter.name || isStringIncludes(d.lastName, filter.name)) &&
            (!filter.name || isStringIncludes(d.firstName, filter.name)) &&
            (!filter.mrn || isStringIncludes(d.mrn, filter.mrn)) &&
            (!filter.dob || moment(d.dob).format(dateFormat) === moment(filter.dob).format(dateFormat)))), 1000);
    });
  }


  setFavoritePatient(id: string, isFavorite: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const t = this.patients.find(d => d.id === id);
        this.patients = this.patients.filter(d => d.id !== id);
        if (t)
          this.patients.push({ ...t, isFavorite });
        resolve();
      }, 100);
    });
  }

  devices: IDevice[] = [
    { id: '1', name: 'NICAS-4501', branch: '1905 Ziwoz Heights', remote: '123', isFavorite: false, ipAddress: '172.61.254.1', macAddress: '00:1B:44:11:3A:B7', model: 'Siemens 123', lastMeasurementAt: new Date('01/01/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Angel Paul', 'Eva Gray', 'Shawn Rodgers'] },
    { id: '2', name: 'NICAS-4502', branch: '1658 Main Circle', remote: '234', isFavorite: true, ipAddress: '127.0.0.1', macAddress: '00:1B:44:11:3A:B7', model: 'Siemens 456', lastMeasurementAt: new Date('01/22/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington'] },
    { id: '3', name: 'NICAS-4503', branch: '3214 Circle Heights', remote: '345', isFavorite: true, ipAddress: '168.1.2.1', macAddress: '00:1B:44:11:3A:B7', model: 'Siemens 678', lastMeasurementAt: new Date('12/30/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Angel Paul'] },
    { id: '4', name: 'NICAS-4505', branch: '1517 Giku River', remote: '456', isFavorite: false, ipAddress: '3.61.4.1', macAddress: '00:1B:44:11:3A:B7', model: 'Siemens 890', lastMeasurementAt: new Date('11/10/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Angel Paul', 'Eva Gray', 'Shawn Rodgers'] },
    { id: '5', name: 'NICAS-4506', branch: '602 Binir Square', remote: '567', isFavorite: true, ipAddress: '21.123.254.1', macAddress: '00:1B:44:11:3A:B7', model: 'Siemens 123', lastMeasurementAt: new Date('10/05/2021'), lastSessionDuration: 1234567, userNames: ['Eva Gray', 'Shawn Rodgers'] },
    { id: '6', name: 'NICAS-4507', branch: '1810 Bazov View', remote: '678', isFavorite: false, ipAddress: '1.61.254.1', macAddress: '00:1B:44:11:3A:B7', model: 'Samsung 123', lastMeasurementAt: new Date('02/04/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Shawn Rodgers'] },
    { id: '7', name: 'NICAS-4508', branch: '1660 Etvi Street', remote: '789', isFavorite: true, ipAddress: '2.61.3.1', macAddress: '00:1B:44:11:3A:B7', model: 'Samsung 456', lastMeasurementAt: new Date('07/07/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Angel Paul', 'Eva Gray', 'Shawn Rodgers'] },
    { id: '8', name: 'NICAS-4520', branch: '943 Fape Place', remote: '890', isFavorite: true, ipAddress: '4.61.5.1', macAddress: '00:1B:44:11:3A:B7', model: 'Samsung 678', lastMeasurementAt: new Date('11/11/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington'] },
    { id: '9', name: 'NICAS-4521', branch: '751 Sepnan Way', remote: '901', isFavorite: false, ipAddress: '2.61.44.1', macAddress: '00:1B:44:11:3A:B7', model: 'Bosch 123', lastMeasurementAt: new Date('12/12/2021'), lastSessionDuration: 1234567, userNames: ['Josie Washington', 'Angel Paul', 'Eva Gray'] },
    { id: '10', name: 'NICAS-4543', branch: '5432 Fifdun Court', remote: '012', isFavorite: true, ipAddress: '22.61.33.1', macAddress: '00:1B:44:11:3A:B7', model: 'Bosch 123', lastMeasurementAt: new Date('01/30/2021'), lastSessionDuration: 1234567, userNames: ['Shawn Rodgers'] },
    { id: '11', name: 'NICAS-4555', branch: '6666 Circle Heights', remote: '1234', isFavorite: false, ipAddress: '127.61.254.1', macAddress: '00:1B:44:11:3A:B7', model: 'Bosch 123', lastMeasurementAt: new Date('01/30/2021'), lastSessionDuration: 1234567, userNames: ['Angel Paul', 'Eva Gray'] },
  ];

  setFavoriteDevice(id: string, isFavorite: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const t = this.devices.find(d => d.id === id);
        this.devices = this.devices.filter(d => d.id !== id);
        if (t)
          this.devices.push({ ...t, isFavorite });
        resolve();
      }, 100);
    });
  }

  fetchDevices(filter: IDeviceFilter): Promise<IDevice[]> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.devices.filter((d) => (filter.favorite === FavoriteFilterType.All || d.isFavorite === (filter.favorite === FavoriteFilterType.Yes)) &&
            (!filter.name || isStringIncludes(d.name, filter.name)) &&
            (!filter.branch || isStringIncludes(d.branch, filter.branch)) &&
            (!filter.lastTestAt || moment(d.lastMeasurementAt).format(dateFormat) === moment(filter.lastTestAt).format(dateFormat))
        ));
      }, 1000);
    });
  }

  users: IUser[] = [
    {
      email: 'admin@nicas.com', branch: '123', firstName: 'John', lastName: 'Doe', id: 'ca8ce066-1f15-400f-a06b-faac84210c04', role: 2, isFavorite: true, phone: '123'
    },
    {
      email: 'op@nicas.com', branch: '123', firstName: 'Jane', lastName: 'Doe', id: 'ca8ce066-1f15-400f-a06b-faac84210c06', role: 1, isFavorite: true, phone: '123'
    }
  ];

  login(email: string, password: string): Promise<IUser | HttpError> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const user = this.users.find(u => u.email === email);
        if (user)
          return resolve(user);

        return resolve({
          status: 403,
          message: 'We couldn’t find an account matching the email and password you entered.'
        });
      }, 2000);
    });
  }
}