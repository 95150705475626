import { Dispatch } from 'redux';
import ServiceProvider from '../../../services/provider';
import { Constants, INavbarNotifierAction } from './types';

export function fetchNotifications(dispatch: Dispatch<INavbarNotifierAction>, timestamp: Date) {
  dispatch({
    type: Constants.FETCH_NOTIFICATIONS_START
  });
  return ServiceProvider.Service.fetchNotifications(timestamp)
    .then((response) => {
      dispatch({
        type: Constants.FETCH_NOTIFICATIONS_END,
        notifications: response
      });
      return response;
    });
}

export function clearAll(dispatch: Dispatch<INavbarNotifierAction>, ids: string[]) {
  return ServiceProvider.Service.clearNotifications(ids)
    .then((response) => {
      dispatch({
        type: Constants.CLEAR_NOTIFICATIONS,
        notifications: []
      });
    });
}