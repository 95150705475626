import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import IdleTimer from "../../utils/IdleTimer";
import { Button } from "primereact/button";
import { onLogout } from "../NavbarProfiler";
import { makeShowIdleModal, makeUser } from "../LoginPage/modules/selectors";
import { logout, showIdleModal } from "../LoginPage/modules/actions";
import { IUser } from "../../services/types";
import ServiceProvider from "../../services/provider";

const idleTime = 5 * 60 * 1000;
const autoLogoutTime = 1 * 60 * 1000;
let timer: IdleTimer;
let autoLogoutTimerId: any = null;

type Props = {
  user: IUser | null;
  showModal: (show: boolean) => void;
  isTimeout: boolean;
};
const IdleModal: React.FunctionComponent<Props> = ({
  user,
  showModal,
  isTimeout,
}) => {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const doLogout = () => {
    onLogout(navigate, () => logout(dispatcher));
    showModal(false);
  };
  useEffect(() => {
    if (user) {
      timer = new IdleTimer({
        timeout: idleTime,
        onTimeout: () => {
          showModal(true);
          ServiceProvider.setTurnOffRequests(true);
          autoLogoutTimerId = setTimeout(() => doLogout(), autoLogoutTime);
        },
        onExpired: () => {
          if (user != null) doLogout();
        },
      });
    }
    return () => {
      if (timer) {
        timer.cleanUp();
      }
    };
  }, [user]);

  const renderer = (props: CountdownRenderProps) => {
    if (props.completed) {
      return null;
    } else {
      return (
        <span>
          {props.formatted.minutes}:{props.formatted.seconds}
        </span>
      );
    }
  };

  const renderFooter = () => (
    <div>
      <Button
        label="Sign Out"
        onClick={() => {
          doLogout();
        }}
        className="p-button-text"
        autoFocus
      />
      <Button
        label="Continue"
        onClick={() => {
          clearTimeout(autoLogoutTimerId);
          timer.start();
          showModal(false);
          ServiceProvider.setTurnOffRequests(false);
        }}
        autoFocus
      />
    </div>
  );

  return (
    user && (
      <Dialog
        header="Notification"
        closable={false}
        footer={renderFooter}
        className="pdf-viewer"
        visible={isTimeout}
        style={{ width: "30vw" }}
        onHide={() => {
          showModal(false);
          ServiceProvider.setTurnOffRequests(false);
        }}
      >
        <p>
          Your session is about to expire due to inactivity. You will be
          automatically signed out in{" "}
        </p>
        <p>
          <Countdown date={Date.now() + autoLogoutTime} renderer={renderer} />
        </p>
      </Dialog>
    )
  );
};

const mapStateToProps = (state) => {
  const user = makeUser()(state);
  const isTimeout = makeShowIdleModal()(state);
  return {
    user,
    isTimeout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (show) => showIdleModal(dispatch, show),
});

const ConnectedIdleModal: React.ComponentType<any> = connect(
  mapStateToProps,
  mapDispatchToProps
)(IdleModal);

export default ConnectedIdleModal;
