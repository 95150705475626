export interface IDictionary<T> {
  [Key: string]: T;
}

export interface INamedModel {
  id: string;
  name: string;
}

export interface IDevice {
  id: string;
  name: string;
  branch: string;
  remote: string;
  isFavorite: boolean;
  ipAddress: string;
  macAddress: string;
  model: string;
  lastMeasurementAt: Date;
  lastSessionDuration: number;
  userNames: string[];
}

export interface IPatient {
  id: string;
  deviceId: string;
  firstName: string;
  lastName: string;
  patient: string;
  gender: boolean;
  mrn: string;
  height: number;
  weight: number;
  dob: Date;
  lastTestAt: Date;
  isOrderReceived: boolean;
  isFavorite: boolean;
  branch: string;
  device?: IDevice;
  lastCaretaker?: ICaretaker;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: number;
  phone: string;
  branch: string;
  isFavorite: boolean;
}

export interface IDeviceLog {
  id: number;
  code: number;
  message: string;
  createdDate: Date;
  logLevel: number;
  deviceId: string;
}

export interface INotification {
  id: string,
  name: string;
  description: string;
  createdAt: Date;
}

export interface ISeries {
  id: string;
  patientId: string;
  isReportExist?: boolean;
  height: number;
  weight: number;
  comments: string;
  duration: number;
  createdAt: Date;
  r: number;
  hr: number;
  tpri: number;
  si: number;
  ci: number;
  cpi: number;
  tbWa: number;
  tbWr: number;
  tbWw: number;
  sensors: Electrodes;
  position: PatientPosition;
  isValid: boolean;
}

export interface ICaretaker {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
}

export interface IExtendedSeries extends ISeries {
  isReportExist?: boolean;
  caretaker: ICaretaker;
  device: IDevice;
}

export enum Electrodes {
  NicasElectrodesLARL = 0,
  NicasElectrodesRALL = 2,
  NicasElectrodesRLLL = 4,
  NicasElectrodesRALA = 8,
  NicasElectrodesRARL = 16,
  NicasElectrodesLALL = 32
}

export enum PatientPosition {
  NicasLaying = 1,
  NicasSitting = 2,
  NicasStanding = 4
}

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5
}

export interface IStatisticItem {
  name: string;
  value: number;
}

export enum UserRole {
  User = 1,
  Admin = 2,
}

export enum DataViewTypes {
  Devices = 1,
  Patients = 2
}