import { IdleKey } from "../containers/LoginPage/modules/actions";

class IdleTimer {
  timeout: number;
  timeoutTracker: NodeJS.Timeout | null = null;
  interval: any = null;
  onTimeout: () => void;

  constructor({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    const lsExpiredTime = localStorage.getItem(IdleKey) || "0";
    const expiredTime = parseInt(lsExpiredTime, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }

    this.start();
  }

  start() {
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem(IdleKey) || "0", 10);
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  }

  updateExpiredTime = (e?) => {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      const expiredTime = Date.now() + this.timeout;
      localStorage.setItem(IdleKey, expiredTime.toString());
    }, 300);
  };

  tracker() {
    window.addEventListener("mousemove", this.updateExpiredTime);
    window.addEventListener("scroll", this.updateExpiredTime);
    window.addEventListener("keydown", this.updateExpiredTime);
  }

  cleanUp() {
    clearInterval(this.interval);
    window.removeEventListener("mousemove", this.updateExpiredTime);
    window.removeEventListener("scroll", this.updateExpiredTime);
    window.removeEventListener("keydown", this.updateExpiredTime);
  }
}
export default IdleTimer;
