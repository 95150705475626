import { Constants, IToasterAction, IToasterState } from './types';

const init: IToasterState = {
  msg: null
}

export default function ToasterReducer(state: IToasterState = init, action: IToasterAction): IToasterState {
  switch (action.type) {
    case Constants.SHOW:
      return { ...state, msg: action.msg };
    case Constants.HIDE:
      return { ...state, msg: null };
    default:
      return state;
  }
}