import { LogLevel } from "./types";

export enum FavoriteFilterType {
  All,
  Yes,
  No,
}

export enum GenderFilterType {
  All,
  Male,
  Female,
}

export enum TimeFilter {
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  Custom,
}

export interface IBaseFilter {
  name?: string;
  lastTestAt?: TimeFilter;
  favorite?: FavoriteFilterType;
}

export interface IPatientFilterThreshold {
  weightMin?: number;
  weightMax?: number;
  heightMin?: number;
  heightMax?: number;
  rMin?: number;
  rMax?: number;
  hrMin?: number;
  hrMax?: number;
  tpriMin?: number;
  tpriMax?: number;
  siMin?: number;
  siMax?: number;
  ciMin?: number;
  ciMax?: number;
  cpiMin?: number;
  cpiMax?: number;
  tbwrMin?: number;
  tbwrMax?: number;
}

export interface IDeviceFilter extends IBaseFilter {
  branch?: string;
}

export interface IPatientFilter extends IBaseFilter {
  gender: GenderFilterType;
  mrn?: string;
  dob?: Date;
  deviceId?: string;
  branch?: string;
  height?: number;
  weight?: number;
  ageFrom?: number;
  ageTo?: number;
  threshold?: IPatientFilterThreshold;
}

export interface IDashboardFilter {
  timeFilter: TimeFilter;
  threshold?: IPatientFilterThreshold;
}

export interface IUserFilter extends IBaseFilter {
  branch?: string;
  phone?: string;
  email?: string;
  lastLoginAt?: Date;
}

export interface IDeviceLogFilter extends IBaseFilter {
  message?: string;
  deviceId?: string;
  createdAt?: TimeFilter;
  logLevel: LogLevel;
}

export interface IFilter {
  id: string;
  name: string;
  filterJson: string;
  type: string;
}
