import React from 'react';

/* eslint-disable react/no-unknown-property */
const InfoIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="pi-info-name">
  <g fill="none" fillRule="evenodd">
    <g>
      <g>
        <g>
          <path d="M0 0H24V24H0z" transform="translate(-16 -255) translate(0 199) translate(16 56)" />
          <path fill="#FFF" d="M12 4c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm1 7h-2v6h2v-6zm0-4h-2v2h2V7z" transform="translate(-16 -255) translate(0 199) translate(16 56)" />
        </g>
      </g>
    </g>
  </g>
</svg>


export default InfoIcon;
/* eslint-enable react/no-unknown-property */