const timeFormat = 'HH:mm:ss';
const dateFormat = 'MM/DD/YYYY'; // 'YYYY-MM-DD' // MM/DD/YYYY
const strongPasswordRegex = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})";

const enum Pages {
  Login ="/login",
  ForgotPassword ="/forgotPassword",
  Patients ="/patients",
  Statistics ="/statistics",
  DeviceErrors ="/devices/:deviceId/:deviceName/errors",
  DevicePatients ="/devices/:deviceId/:deviceName/patients",
  Users ="/users",
  Logs ="/logs",
  Report ="/report",
  Devices ="/devices",
  Bireport ="/bireport",
  ResetPassword ="/reset/:token",
  HomePage ="/",
}

export {
  timeFormat,
  dateFormat,
  strongPasswordRegex,
  Pages
}