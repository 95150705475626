import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import ConnectedNavbar from './containers/Navbar'
import AsyncLoginPage from './containers/LoginPage/loadable'
import AsyncForgotPasswordPage from './containers/ForgotPasswordPage/loadable'
import AsyncDeviceErrorsPage from './containers/DeviceErrorsPage/loadable'
import AsyncDevicePatientsPage from './containers/DevicePatientsPage/loadable'
import AsyncDashboardPage from './containers/DashboardPage/loadable'
import AsyncErrorsPage from './containers/DeviceLogPage/loadable'
import AsyncUsersPage from './containers/UsersPage/loadable'
import ProgressLoader from './containers/ProgressLoader'
import AsyncReportPage from './containers/ReportPage/Loadable'
import AsyncResetPasswordPage from './containers/ResetPasswordPage/loadable'
import AsyncBIReportPage from './containers/BIReportPage/Loadable'
import AsyncPatientsPage from './containers/PatientsPage/loadable'
import AsyncDevicesPage from './containers/DevicesPage/loadable'
import Toaster from './containers/Toaster'
import { makeUser } from './containers/LoginPage/modules/selectors'
import UserPermissions from './containers/UserPermissions'
import { Pages } from './constants'
import IdleModal from './containers/IdleModal'

const PrivateRoute = ({ children, ...rest }) => {
  const user = useSelector(state => makeUser()(state));
  return user ? <>
    <ConnectedNavbar />
    <div className="container-nicas" {...rest}>
      {children}
    </div>
  </> : <Navigate to={Pages.Login} />;
}

const App: React.FC = () => <BrowserRouter basename={process.env.PUBLIC_URL}>
  <Toaster />
  <ProgressLoader />
  <IdleModal />
  <UserPermissions>
    <Routes>
      <Route path={Pages.Login} element={<AsyncLoginPage />} />
      <Route path={Pages.ForgotPassword} element={<AsyncForgotPasswordPage />} />
      <Route path={Pages.Patients} element={<PrivateRoute><AsyncPatientsPage /></PrivateRoute>} />
      <Route path={Pages.Devices} element={<PrivateRoute><AsyncDevicesPage /></PrivateRoute>} />
      <Route path={Pages.Statistics} element={<PrivateRoute><AsyncDashboardPage /></PrivateRoute>} />
      <Route path={Pages.DeviceErrors} element={<PrivateRoute><AsyncDeviceErrorsPage /></PrivateRoute>} />
      <Route path={Pages.DevicePatients} element={<PrivateRoute><AsyncDevicePatientsPage /></PrivateRoute>} />
      <Route path={Pages.Users} element={<PrivateRoute><AsyncUsersPage /></PrivateRoute>} />
      <Route path={Pages.Logs} element={<PrivateRoute><AsyncErrorsPage /></PrivateRoute>} />
      <Route path={Pages.ResetPassword} element={<AsyncResetPasswordPage />} />
      <Route path={Pages.Report} element={<PrivateRoute><AsyncReportPage /></PrivateRoute>} />
      <Route path={Pages.Bireport} element={<PrivateRoute className="container-nicas bireport"><AsyncBIReportPage /></PrivateRoute>} />
      <Route path={Pages.HomePage} element={<AsyncLoginPage />} />
    </Routes>
  </UserPermissions>
</BrowserRouter>

export default App
