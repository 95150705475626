import React from 'react';
import { connect } from 'react-redux';
import { makeToasterMessage } from './modules/selectors';
import Toaster from '../../components/Toaster';

interface Props {
}

const mapStateToProps = state => {
  const msg = makeToasterMessage()(state);
  return {
    ...state,
    msg
  };
};

const ConnectedToaster: React.ComponentType<Props> = connect(
  mapStateToProps,
  null,
)(Toaster);

export default ConnectedToaster;