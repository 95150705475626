import { IUser } from '../../../services/types';

export enum Constants {
  FETCH_USERS_START = 'FETCH_USERS_START',
  FETCH_USERS_END = 'FETCH_USERS_END',
  SET_USER_TO_EDIT = 'SET_USER_TO_EDIT',
}

export interface IUserAction {
  type: string;
  users?: IUser[];
  user?: IUser | null;
}

export interface IUserState {
  users: IUser[];
  user: IUser | null;
}