import { IDevice, IPatient, ISeries } from '../../../services/types';

export enum Constants {
  FETCH_DEVICES_START = 'FETCH_DEVICES_START',
  FETCH_DEVICES_END = 'FETCH_DEVICES_END',
}

export interface IDevicesAction {
  type: string;
  devices?: IDevice[];
}

export interface IDevicesState {
  devices: IDevice[];
}