import { Constants, IPatientsAction, IPatientsState } from './types';

const init: IPatientsState = {
  patients: [],
}

export default function PatientPageReducer(state: IPatientsState = init, action: IPatientsAction): IPatientsState {
  switch (action.type) {
    case Constants.FETCH_PATIENTS_END:
      return { ...state, patients: action.patients || [] };
    default:
      return state;
  }
}