import { Constants, IDeviceErrorsState, IDeviceErrorsAction } from './types';

const init: IDeviceErrorsState = {
  deviceErrors: []
}

export default function ErrorsPageReducer(state: IDeviceErrorsState = init, action: IDeviceErrorsAction): IDeviceErrorsState {
  switch (action.type) {
    case Constants.FETCH_DEVICE_ERRORS_END:
      return { ...state, deviceErrors: action.deviceErrors || [] };
    default:
      return state;
  }
}