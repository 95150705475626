import { combineReducers, createStore, applyMiddleware } from 'redux'
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync'
import ProgressLoaderReducer from './containers/ProgressLoader/modules/reducer'
import LoginReducer from './containers/LoginPage/modules/reducer'
import PatientPageReducer from './containers/PatientsPage/modules/reducer'
import ToasterReducer from './containers/Toaster/modules/reducer'
import NavbarNotifierReducer from './containers/NavbarNotifier/modules/reducer'
import ErrorsPageReducer from './containers/DeviceErrorsPage/modules/reducer'
import UsersReducer from './containers/UsersPage/modules/reducer'
import DevicePageReducer from './containers/DevicesPage/modules/reducer'

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  progressLoader: ProgressLoaderReducer,
  toaster: ToasterReducer,
  login: LoginReducer,
  notifier: NavbarNotifierReducer,
  errorsPage: ErrorsPageReducer,
  usersPage: UsersReducer,
  devicesPage: DevicePageReducer,
  patientsPage: PatientPageReducer,
})

const config = {
  blacklist: [],
};
const middlewares = [createStateSyncMiddleware(config)];

const store = createStore(
  rootReducer,
  applyMiddleware(...middlewares),
);

initMessageListener(store);

export default store
