import { Constants, IProgressLoaderAction, IProgressLoaderState } from './types';

const init: IProgressLoaderState = {
  isVisible: false
}

export default function ProgressLoaderReducer(state: IProgressLoaderState = init, action: IProgressLoaderAction): IProgressLoaderState {
  switch (action.type) {
    case Constants.SHOW:
      return { ...state, isVisible: true };
    case Constants.HIDE:
      return { ...state, isVisible: false };
    default:
      return state;
  }
}